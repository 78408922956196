@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

// Define module less variables
@auth-container-default-cls: ~'@{ra-prefix}-auth-container';
@auth-container-with-background-cls: ~'@{auth-container-default-cls}-with-background';
@auth-container-background-cls: ~'@{auth-container-default-cls}-background';
@auth-container-wrapper-cls: ~'@{auth-container-default-cls}-wrapper';
@auth-container-framed-cls: ~'@{auth-container-default-cls}-framed';
@auth-container-header-cls: ~'@{auth-container-default-cls}-header';
@auth-container-form-cls: ~'@{auth-container-default-cls}-form';
@auth-container-footer-cls: ~'@{auth-container-default-cls}-footer';
@auth-container-subfooter-cls: ~'@{auth-container-footer-cls}-sub';
@auth-container-logo-cls: ~'@{auth-container-default-cls}-logo';
@auth-container-hint-cls: ~'@{auth-container-default-cls}-hint';

// Export module less variables
:export {
  authContainerCls: @auth-container-default-cls;
  authContainerWithBackgroundCls: @auth-container-with-background-cls;
  authContainerBackgroundCls: @auth-container-background-cls;
  authContainerWrapperCls: @auth-container-wrapper-cls;
  authContainerFramedCls: @auth-container-framed-cls;
  authContainerHeaderCls: @auth-container-header-cls;
  authContainerFormCls: @auth-container-form-cls;
  authContainerFooterCls: @auth-container-footer-cls;
  authContainerSubFooterCls: @auth-container-subfooter-cls;
  authContainerLogoCls: @auth-container-logo-cls;
  authContainerHintCls: @auth-container-hint-cls;
}

.@{auth-container-default-cls} {
  height: 100%;

  &.ra-section {
    &.@{auth-container-with-background-cls} {
      margin: 0;
      padding-top: 0;

      @media only screen and (min-width: @ra-screen-sm) {
        padding-top: 80px;
      }

      .@{auth-container-wrapper-cls} {
        &.ra-wrapper {
          @media only screen and (max-width: @ra-screen-xs-max) {
            padding-left: 0;
            padding-right: 0;
          }
        }

        @media only screen and (min-width: @ra-screen-sm) {
          max-width: 650px;
        }
      }

      .@{auth-container-form-cls} {
        @media only screen and (min-width: @ra-screen-sm) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }

  .@{auth-container-wrapper-cls} {
    @media only screen and (min-width: @ra-screen-sm) {
      max-width: 600px;
    }
  }

  .@{auth-container-framed-cls} {
    // Required expicit width: 100% for IE11 because it does not aligns properly flexbox form items in case of the quiz login/registration
    width: 100%;
    padding: 0;
    background-color: @ra-color-white;

    @media only screen and (min-width: @ra-screen-sm) {
      padding: 25px;
      // stylelint-disable-next-line function-no-unknown
      background-color: fade(@ra-color-white, 95%);
    }

    .@{auth-container-form-cls} {
      padding: 0 20px;
    }
  }

  .@{auth-container-header-cls} {
    text-align: center;

    .ra-heading {
      color: @ra-color-white;
    }

    > div {
      padding: 50px 30px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    @media only screen and (min-width: @ra-screen-sm) {
      .ra-heading {
        color: @ra-color-text;
      }

      > div {
        padding: 0;
        background: transparent;
      }
    }

    .@{auth-container-hint-cls} {
      margin: 20px 0;
    }
  }

  .@{auth-container-logo-cls} {
    margin-bottom: 20px;

    .ra-logo {
      width: 230px;
      margin: 0 auto;
    }
  }

  .@{auth-container-form-cls} {
    margin-top: 20px;
    word-break: keep-all;

    @media only screen and (min-width: @ra-screen-sm) {
      max-width: 100%;
      margin-top: 10px;
      padding: 0 35px;
    }

    .ra-subscribe-form-wrapper .ra-subscribe-form-bottom {
      width: 100%;
    }
  }

  .@{auth-container-footer-cls} {
    text-align: center;
    word-break: keep-all;
    opacity: 1;

    @media only screen and (min-width: @ra-screen-sm) {
      margin: 10px auto;
      padding: 0 10px;
    }
  }

  .@{auth-container-subfooter-cls} {
    margin: 10px 0 0;
    padding: 0 20px 20px;
    text-align: center;

    @media only screen and (min-width: @ra-screen-sm) {
      margin: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      // stylelint-disable-next-line function-no-unknown
      background-color: fade(@ra-color-white, 95%);
      border-top: 1px solid @ra-color-border;

      > p {
        margin: 0;
      }
    }
  }
}

.@{auth-container-background-cls} {
  min-height: calc(~'100vh - 50px');

  @media only screen and (min-width: @ra-screen-lg) {
    min-height: calc(~'100vh - 60px');
  }
}
